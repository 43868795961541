<template>
  <main>
    <transition name="slide-fade">
      <div v-if="show">
        <svg width="230" height="180" data-v-fae5bece="">
          <rect
            x="90"
            y="10"
            rx="20"
            ry="20"
            width="50"
            height="120"
            data-v-fae5bece=""
            style="fill: transparent;stroke: black;stroke-width: 5;opacity: 0.5;"
          ></rect>
          <rect
            x="40"
            y="40"
            rx="20"
            ry="20"
            width="150"
            height="120"
            data-v-fae5bece=""
            style="fill: gray;stroke: gray;stroke-width: 5;/* opacity: 0.5; */"
          ></rect>
        </svg>
      </div>
    </transition>
  </main>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    show: false
  }),
  mounted() {
    this.show = true;
  }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>

<style scoped>
main {
  display: grid;
  place-content: center;
}
</style>
