import { ref, computed } from "vue";

const state = ref({
  loading: false
});

export default {
  state,
  isLoading: computed(() => state.value.loading)
};
