import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "./styling/global.css";
import db from "./db";
import global from "./global";

createApp(App)
  .use(store)
  .use(router)
  .provide("global", global)
  .provide("db", db)
  .mount("#app");
