import { writeFile } from "./fileSystemAccessAPI";

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/**
 * collection - the collection name, I.E. 'tasks' results in yourdir/database/tasks
 * TODO: Replace collectionName with generic getCollection(args all or filter etc)
 */
export default class Database {
  constructor(proposed_breefkase_dir) {
    this.databaseIsReady = false; // may not be needed
    this.breefkaseDirPath = proposed_breefkase_dir; // Not needed for filesystem access API. Useful with electron and cordova
    this.topLevelEntries = [];

    // File system access API specific
    this.breefkaseDirectoryHandle = false;
    this.collection = {};

    /**
     * Methods for detecting if node.js / electron / browser (native file system API) / cordova
     * File system access API:
     *    https://web.dev/file-system-access/
     */

    /* TODO: fix Path 
    if (this.breefkaseDir && this.collectionName) {
      this.verifyCollection(collectionName);
      
        this.archivePath = Path(self.breefkase_dir).joinpath("database", "archive")
        self.archive_filepath = Path(self.archive_path).joinpath(f'{self.collection_name}.json')
      }
     */
  }

  get breefkaseDirName() {
    return this.breefkaseDirectoryHandle.name;
  }

  /**
   * Alias for initialize()
   */
  async start() {
    this.initialize();
  }

  /**
   *  File system access API requires Chrome 86 or higher
   */
  async initialize() {
    if ("showDirectoryPicker" in window) {
      try {
        const dirHandle = await window.showDirectoryPicker();
        this.breefkaseDirectoryHandle = dirHandle;
        this.databaseIsReady = true;
        for await (const entry of dirHandle.values()) {
          this.topLevelEntries.push({
            kind: entry.kind,
            name: entry.name
          });
        }
      } catch (error) {
        if (error instanceof DOMException) {
          return "Directory request was aborted";
        } else {
          return error;
        }
      }
    } else {
      return "Filesystem access API not supported in this browser";
    }
  }

  /**
   * TODO: Consider using include_docs: boolean as in PouchDB
   * TODO Option to skip storing to "memory"
   * Every item carries a fileHandle containing, among others, kind(file or directory), name(file name), size, lastModified
   * @param {*} collectionDirectoryName - May be too memory ineffective. Ad-hoc loading preferred?
   */
  async loadCollection(collectionDirectoryName) {
    const databaseDirectoryHandle = await this.breefkaseDirectoryHandle.getDirectoryHandle(
        "database"
      ),
      collectionDirectoryHandle = await databaseDirectoryHandle.getDirectoryHandle(
        collectionDirectoryName
      ),
      items = [];
    this.collection[collectionDirectoryName] = {
      directoryHandle: collectionDirectoryHandle,
      entries: []
    };
    try {
      for await (let entry of collectionDirectoryHandle.values()) {
        const file = await entry.getFile(),
          contents = await file.text();
        items.push({
          fileHandle: entry,
          meta: file,
          doc: JSON.parse(contents)
        });
      }
      this.collection[collectionDirectoryName].entries = items;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * TODO: If necessary, implement docitem=None, doc=None
   * TODO: Evaluate need for :param filepath: Full path to file - I.E. /home/user/breefkase/database/tasks/1234-4321-abcd-dcba.json
   * @param {*} collectionDirectoryName - I.E. "tasks"
   * @param {*} fileName - Document ID, should reflect filename (and _id property), sans file extension/suffix.
   */
  getOne(collectionDirectoryName, fileName) {
    const collection = this.collection[collectionDirectoryName].entries,
      index = collection.findIndex(item => item.fileHandle.name === fileName),
      task = collection[index];
    return task;
  }

  /**
   * Insert document to "database"
   * @param {*} docItem - A docitem
   */
  insert(docItem) {
    console.log(docItem);
    // TODO: Test write in briefcase test/dev directory
    if (docItem.doc._id === "new") {
      docItem.doc._id = uuidv4();
      // TODO: New file requires a new filehandle
      // https://web.dev/file-system-access/#write-file
    } else {
      docItem.doc.updated = new Date().toISOString();
    }
    const contents = JSON.stringify(docItem.doc, null, 2);
    writeFile(docItem.fileHandle, contents);
  }

  errorHandler(obj) {
    console.log(obj.error);
    obj = {
      msg: obj.msg,
      error: {
        message: obj.error.message,
        stack: obj.error.stack
      }
    };
    sessionStorage.setItem(`errorHandler${new Date().toISOString()}`, JSON.stringify(obj));
  }
}

/**
From python

def make_docitem(filepath):
    """
    Requires Pathlib object. I.E. Posixpath <class 'pathlib.PosixPath'>
    :param filepath:
    :return:
    """
    # TODO: Send to log if parsing JSON fails, informing user of an incorrectly formatted/corrupt/broken document
    docitem = {
        "filepath": filepath,
        "filename": filepath.name,
        "doc": {},
    }
    try:
        with open(filepath, 'r') as file:
            file_contents = file.read()
            docitem["doc"] = json.loads(file_contents)
    except Exception as error:
        docitem["doc"] = {
            "title": "File could not be loaded",
            "due": None,
            "priority": None,
            "status": "plan",
            "error": str(error),
        }
    return docitem


class Database:

    def verify_breefkase_dir(self, proposed_breefkase_dir):
        print("Verifying breefkase directory")
        # TODO parse/validate directory
        # TODO create dir 'database' if not exists
        # TODO create dir 'database/tasks' if not exists
        try:
            bp = Path(proposed_breefkase_dir)
            if Path.is_dir(bp):
                self.breefkase_dir = bp
                print("Database directory validated")
                settings = Settings()
                if str(self.breefkase_dir) != settings.config.get("breefkaseDir", None):
                    settings.config["breefkaseDir"] = str(self.breefkase_dir)
                    settings.save()
        except TypeError as te:
            print("Invalid directory path. Try using full path, I.E. /home/user/mystuff. Error: ", te)

    def verify_collection(self, collection_name):
        # TODO break on invalid breefkase_dir (None)
        if self.breefkase_dir:
            try:
                collection_path = Path(self.breefkase_dir).joinpath('database', collection_name)
                collection_path.mkdir(parents=True, exist_ok=True)
                self.collection_path = collection_path
                print("Collection verified")
            except Exception as error:
                print(f'Something went wrong when verifying collection {collection_name}: {error}')

    def fetchall(self):
        docs = []
        for filepath in self.collection_path.iterdir():
            name = filepath.name
            if name.endswith(".json"):
                docitem = make_docitem(filepath)
                docs.append(docitem)
        return docs

    def delete(self, filepath):
        if not isinstance(filepath, PurePath):
            filepath = Path(filepath)
        filepath.unlink()
        print("Deleted doc: ", filepath)

    def archive(self, require_confirmation=True):
        """
        Archives all items carrying the property archived:true
        """
        new_archive = []
        counts = {
            "items_in_archive_file_pre_merge": 0,
            "items_for_archival": 0,
            "items_in_archive_file_post_merge": 0,
        }
        filepaths_for_deletion = []
        self.archive_path.mkdir(parents=True, exist_ok=True)
        if self.archive_filepath.is_file():
            print("Archive file exist. Loading contents...")
            with open(self.archive_filepath, 'r') as file:
                archive_file_contents = file.read()
                for doc in json.loads(archive_file_contents):
                    new_archive.append(doc)
            counts["items_in_archive_file_pre_merge"] += len(new_archive)
        docs = self.fetchall()
        for d in docs:
            if d["doc"].get("archived", None):
                new_archive.append(d["doc"])
                filepaths_for_deletion.append(d["filepath"])
        if not len(filepaths_for_deletion):
            print("No items for archival")
            return
        counts["items_for_archival"] += len(filepaths_for_deletion)
        counts["items_in_archive_file_post_merge"] += len(new_archive)
        totals_before = counts["items_for_archival"] + counts["items_in_archive_file_pre_merge"]
        totals_after = counts["items_in_archive_file_post_merge"]
        if require_confirmation:
            print("Remember backing up files before archiving.")
            answer = input(f"Archive {len(filepaths_for_deletion)} items (Y/n)? ")
            if answer != "Y":
                print("Aborted")
                return
        if totals_before == totals_after:
            with open(self.archive_filepath, 'w') as afile:
                afile.write(json.dumps(new_archive, ensure_ascii=False))
                print("Wrote items to archive")
            for fpath in filepaths_for_deletion:
                fpath.unlink()
                print("File deleted: ", fpath)
                # TODO: Post-check that all item ID's are present in archive file
            return counts
        else:
            print("Item counts did not match. Aborting archival")

    def unarchive(self):
        print(f"Moving items out of archive and into {self.collection_path}")
        with open(self.archive_filepath, 'r') as file:
            archive_file_contents = file.read()
            for doc in json.loads(archive_file_contents):
                doc["archived"] = False
                filepath = Path(self.collection_path).joinpath(f'{doc["_id"]}.json')
                docitem = {
                    "filepath": filepath,
                    "filename": filepath.name,
                    "doc": doc,
                }
                self.insert(docitem)
        with open(self.archive_filepath, 'w') as file:
            file.write(json.dumps([], ensure_ascii=False))


 */
