<template>
  <div v-if="global.isLoading.value">
    <span class="dots">
      ....
    </span>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  setup() {
    const global = inject("global");

    return {
      global
    };
  }
};
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  right: 0;
  margin: 2vh;
  background-color: var(--surface);
  padding: 0.6rem;
  border-top: 1px dotted var(--primary);
  box-shadow: var(--card-shadow);
}

.dots {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  display: block;
  animation-name: dots;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes dots {
  0% {
    width: 0%;
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
</style>
